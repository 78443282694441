<div class="container bta-footer" id="footer">
    <div class="row">
        <div class="col-lg-4 col-12">
            <img src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/b2b-logo.png" height="70">
            <p class="small fw-medium mt-3">© 2024 B2B Travel Agency – All rights reserved</p>
        </div>
        <div class="col-lg-8 col-12 text-lg-end">
            <div class="mt-lg-5"><a href="#">Company</a> <a href="#">Products </a> <a href="#">Contact</a> <a
                    href="#">FAQ</a></div>
            <div class="mt-2 small">
                <a href="#">Privacy Policy</a> | <a href="#">Disclaimer</a> | <a href="#">Terms & Conditions</a>
                | <a href="#">User Agreement</a> | <a href="#">Copyright Information's</a>
            </div>
        </div>
    </div>
</div>