import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class DataShareService {
  private searchSubject = new BehaviorSubject<any>('');
  searchSubject$ = this.searchSubject.asObservable();
  private adminPanelType = new BehaviorSubject<string>('');
  public adminPanel = this.adminPanelType.asObservable();
  private countrySubject = new BehaviorSubject<any[]>([]);
  countrySubject$ = this.countrySubject.asObservable();

  private tableDataSubject = new BehaviorSubject<any[]>([]);

  tableDataSubject$ = this.tableDataSubject.asObservable();
  private categorySubject = new BehaviorSubject<any[]>([]);
  categorySubject$ = this.categorySubject.asObservable();
  private typeSubject = new BehaviorSubject<any[]>([]);
  typeSubject$ = this.typeSubject.asObservable();
  private counrtyCodeSubject = new BehaviorSubject<any[]>([]);
  counrtyCodeSubject$ = this.counrtyCodeSubject.asObservable();
  private borderSetSubject = new BehaviorSubject<boolean>(
    this.storageService.getSessionStorageItem('proxyToken') ? true : false
  );
  borderSetSubject$ = this.borderSetSubject.asObservable();
  status = [
    { value: 'active', viewValue: 'Active' },
    { value: 'inactive', viewValue: 'Inactive' },
    { value: 'pending', viewValue: 'Pending' },
  ];

  holidayStatusList = [
    {
      label: 'ACTIVE',
      value: 'Active',
    },
    {
      label: 'PENDING',
      value: 'Pending',
    },
    {
      label: 'INACTIVE',
      value: 'Inactive',
    },
    {
      label: 'REJECTED',
      value: 'Rejected',
    },
  ];

  countryWithStates = [
    {
      country: 'India',
      states: [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
      ],
    },
    {
      country: 'United Arab Emirates',
      states: [
        'Abu Dhabi',
        'Dubai',
        'Sharjah',
        'Ajman',
        'Umm Al-Quwain',
        'Fujairah',
        'Ras Al Khaimah',
      ],
    },
    {
      country: 'Canada',
      states: [
        'Alberta',
        'British Columbia',
        'Manitoba',
        'New Brunswick',
        'Newfoundland and Labrador',
        'Nova Scotia',
        'Ontario',
        'Prince Edward Island',
        'Quebec',
        'Saskatchewan',
        'Northwest Territories',
        'Nunavut',
        'Yukon',
        'Yukon Territory',
      ],
    },
    {
      country: 'United States of America',
      states: [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Dictrict of Columbia',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
      ],
    },
    {
      country: 'United Kingdom of Great Britain and Northern Ireland',
      states: [
        'East of England',
        'East Midlands',
        'London',
        'North East',
        'North West',
        'South East',
        'South West',
        'West Midlands',
        'Scotland',
        'Wales',
        'Northern Ireland',
      ],
    },
  ];

  private creaditDetails = new BehaviorSubject<any[]>([]);
  creaditDetails$ = this.creaditDetails.asObservable();

  private flightSearchDetails = new BehaviorSubject<any[]>([]);
  flightSearchDetails$ = this.flightSearchDetails.asObservable();

  // flight booking id==============================================
  private flightBookingIdSubject = new BehaviorSubject<any | null>(
    this.getflightBookingIdFromLocalStorage()
  );
  flightBookingId$ = this.flightBookingIdSubject.asObservable();

  //flight passenger details emission=====================================================
  private passengerDetailsSubject = new BehaviorSubject<any | null>(
    this.getPassengerDetails()
  );
  passengerDetailsSubject$ = this.passengerDetailsSubject.asObservable();

  private modifySearchSubject = new BehaviorSubject<any>('');
  modifySearchSubject$ = this.modifySearchSubject.asObservable();

  constructor(private storageService: StorageService) {}

  getadminPanelType(): Observable<string> {
    return this.adminPanelType.asObservable();
  }

  setAdminPanelType(adminType: string): void {
    this.adminPanelType.next(adminType);
  }

  setAllCountry(value: any[]) {
    this.countrySubject.next(value);
  }
  setModifySearchRes(value: any) {
    console.log('observable', value);
    this.modifySearchSubject.next(value);
  }

  setTableData(value: any[]) {
    this.tableDataSubject.next(value);
  }

  setCategoryData(value: any[]) {
    this.categorySubject.next(value);
  }

  setTypeData(value: any[]) {
    this.typeSubject.next(value);
  }

  searchList(value: any) {
    console.log(value)
    this.searchSubject.next(value);
  }

  setCountryCodes(value: any[]) {
    this.counrtyCodeSubject.next(value);
  }

  setCreaditValue(value: any) {
    this.creaditDetails.next(value);
  }

  setFlightSearchDetails(value: any) {
    this.flightSearchDetails.next(value);
  }

  private getflightBookingIdFromLocalStorage(): Observable<any | null> {
    const flightBookingId =
      this.storageService.getLocalStorageItem('flightBookingId');
    return flightBookingId ? flightBookingId : '';
  }

  // Method to set ID
  setflightBookingId(flightBookingId: any): void {
    if (flightBookingId) {
      this.storageService.setLocalStorageItem(
        'flightBookingId',
        flightBookingId
      );
      this.flightBookingIdSubject.next(flightBookingId);
    }
  }

  getFlightBookingIdObservable(): Observable<any | null> {
    return this.flightBookingIdSubject.asObservable();
  }

  private getPassengerDetails(): Observable<any | null> {
    const PassengerDetails =
      this.storageService.getLocalStorageItem('passengerDetails');
    return PassengerDetails ? PassengerDetails : '';
  }

  //flight passenger Data emission===========
  setPassengerDetails(value: any[]) {
    if (value) {
      this.storageService.setLocalStorageItem('passengerDetails', value);
      this.passengerDetailsSubject.next(value);
    }
  }

  setBorderData(value: boolean) {
    this.borderSetSubject.next(value);
  }

  getStates(countryName: string) {
    const selectedStates = this.countryWithStates.filter(
      (res) => res?.country?.toLowerCase() == countryName.toLocaleLowerCase()
    )[0]?.states;
    return selectedStates;
  }
}
