<div class="modal-content p-4">
  <div class="modal-header p-2 text-center d-block">
    <img class="swal2-info" src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/icons/icons8-info.svg" />
    <h2 class="modal-title w-100 text-center">Delete {{ details.type }}</h2>
  </div>

  <div class="modal-body pb-3 text-center">
    <p>
      Are you sure to delete
      <b [innerHTML]="details.title ? details.title : ''"></b>?
    </p>
  </div>
  <div class="text-center">
    <button
      type="button"
      class="btn add-button me-2"
      (click)="dialogRef.close('no')"
    >
      Close
    </button>
    <button type="button" class="btn btn-danger " (click)="deleteConfirm()">
      <mat-icon *ngIf="isDeleting" class="spin">sync</mat-icon> Yes, delete it!
    </button>
  </div>
</div>
