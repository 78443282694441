import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Input() isUpdated: any;
  @Input() view_status;
  @Input() title: string;
  @Input() isShown;
  @Output() closeButton = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  dialogRefClose(){
    this.closeButton.emit(true);
  }
}
