import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { WhitelabelService } from 'src/app/core/services/whitelabel.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { StaticPageComponent } from '../../static-page/static-page.component';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [CommonModule, RouterModule, StaticPageComponent],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})

export class PageNotFoundComponent {

  public imageUrl = environment?.imageUrl;
  htmlContent: SafeHtml | null = null;
  loading = false;
  isStaticContent = false;

  constructor(private api: WhitelabelService, private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.loading = true;
    this.api.getPageContent({ path: this.router.url }).subscribe({
      next: (res: any) => {
        if (res?.static) {
          this.isStaticContent = true;
          this.loadHtmlContent(res?.html);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  loadHtmlContent(html) {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
