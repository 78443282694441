import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { ToastService } from '../services/toast.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService, private router: Router,
    private storageService: StorageService, private tostrService: ToastService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 && request.url.includes('/token/refresh/')) {
          this.storageService.clearLocalStorage();
          this.storageService.clearSessionStorage();
          this.router.navigateByUrl('/')
          return;
        }
        if (err.status === 409) {
          this.tostrService.error(err.error.message, 'Cancel');
          return;
        }
        if (err.status === 401) {
          const userDetails = this.storageService.getSessionStorageItem('token');
          const dataToSend = {
            refresh: userDetails.refresh_token,
          };
          return this.authenticationService
            .generateAccessToken(dataToSend)
            .pipe(
              switchMap((response: any) => {

                const token = {
                  access_token: response.access,
                  refresh_token: response.refresh,
                };
                const newAccessToken = response.access;
                this.storageService.setSessionStorageItem('token', token);
                return next.handle(this.addToken(request, newAccessToken));
              }),
              catchError((err) => {
                if (err.status === 401) {
                  this.storageService.clearLocalStorage();
                  this.router.navigateByUrl('/')
                }
                return throwError(err);
              })
            );
        }

        const error = err;
        return throwError(error);
      })
    );
  }
  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
