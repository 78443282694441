<mat-card class="p-4">
  <mat-card-title class="m-0">
    <div class="card-title-text pl-4 rtl:pr-4 py-3">
      Gallery
      <div class="float-end">
        <button
          class="me-2 btn"
          mat-icon-button
          (click)="dialogRef.close()"
          aria-label="Close dialog"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-title>
  <div class="row">
    <div class="row">
      <div class="col-md-10 text-end">
        <mat-paginator
          [length]="pagination?.length"
          [pageSize]="pagination?.pageSize"
          [pageIndex]="pagination?.pageIndex"
          [pageSizeOptions]="pagination?.pageSizeOptions"
          aria-label="Select page"
          (page)="pageChangedFun($event)"
        >
        </mat-paginator>
      </div>
    </div>
    <div
      class="col-md-8"
      [ngClass]="{ 'col-md-12': !isForm, 'col-md-8': isForm }"
    >
      <mat-card-content class="!pt-4">
        <mat-grid-list
          [cols]="isForm ? 4 : 5"
          rowHeight="1:1"
          class="app-gallery"
        >
          <mat-grid-tile
            class="cpr bdr"
            *ngFor="let photo of galleryList; let i = index"
            (click)="onSelectImage(photo, i)"
            [ngClass]="{ selected: photo.selected }"
          >
            <img [src]="photo.url" alt="" *ngIf="fileType == 'image'" />
            <img
              width="100"
              src="assets/images/svg-icons/file.svg"
              alt=""
              *ngIf="fileType == 'file'"
            />
            <div class="gallery-control-wrap !h-14 flex items-center">
              <div class="gallery-control flex items-center w-full">
                <h4 class="photo-detail fz-1">{{ photo.name }}</h4>
                <span class="flex-grow"></span>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="photoMenu"
                  class="mat-icon-button-style"
                >
                  <mat-icon class="matIcon">more_vert</mat-icon>
                </button>
                <mat-menu #photoMenu="matMenu">
                  <button
                    mat-menu-item
                    (click)="resetGalleryForm(photo); isForm = true"
                  >
                    <mat-icon>edit</mat-icon>Update
                  </button>
                  <button mat-menu-item (click)="deleteImage(photo)">
                    <mat-icon>delete</mat-icon>Delete
                  </button>
                </mat-menu>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </div>
    <div class="col-md-4" *ngIf="isForm">
      <mat-card-content>
        <form [formGroup]="imageForm" (ngSubmit)="onImageSubmit(imageForm)">
          <div class="row">
            <div class="col-md-12" *ngIf="!imageForm.value.url">
              <label class="col-form-label">URL</label>
              <div class="d-flex">
                <input
                  id="uploadImg"
                  class="form-control"
                  type="file"
                  accept="{{ fileType }}/*"
                  (change)="handleIcon($event.target.files)"
                  class="form-control"
                  [(ngModel)]="selectedImage"
                  placeholder="Upload Icon"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
            <div class="col-md-12 d-flex p-3" *ngIf="imageForm.value.url">
              <img class="m-auto" width="100%" [src]="imageForm.value.url" />
            </div>
            <!-- <div class="col-md-12">
                <label class="col-form-label">Title</label>
                <input type="text" class="form-control" placeholder="Enter Title" formControlName="title">
              </div> -->
            <div class="col-md-12">
              <label class="col-form-label">Heading</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Title"
                formControlName="name"
              />
            </div>
            <div class="col-md-12">
              <label class="col-form-label">Alternative Text</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Alt Text"
                formControlName="alternative_text"
              />
            </div>
            <div class="col-md-12 text-end mt-2">
              <div class="my-2 progress-bar" *ngIf="progress > 0">
                <div class="progress" [style.width.%]="progress">
                  {{ progress }}%
                </div>
              </div>
              <button class="btn btn-primary">
                <i
                  *ngIf="isUpdating"
                  class="fa fa-pulse fa-spin fa-spinner"
                ></i>
                {{ imageForm.value.url ? "Update" : "Add" }} Image
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </div>
  </div>
  <mat-card-footer>
    <div class="container text-end py-3">
      <button
        class="btn btn-danger me-2"
        (click)="dialogRef.close()"
        aria-label="Close dialog"
      >
        Close
      </button>
      <button
        class="btn btn-primary me-2"
        (click)="resetGalleryForm(); isForm = !isForm"
      >
        Upload new {{ fileType }}
      </button>
      <button (click)="onSubmit()" class="btn btn-success add-button">
        Select {{ fileType }}{{ imgCount > 1 ? "s" : "" }}
      </button>
    </div>
  </mat-card-footer>
</mat-card>
