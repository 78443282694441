import { Component } from "@angular/core";
import { cl } from "@fullcalendar/core/internal-common";
import { WhitelabelService } from "../core/services/whitelabel.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
    selector: 'BTA-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})

export class HomePageComponent {

    public loginStatus: Boolean = false;
    public imageUrl = environment?.imageUrl;
    htmlContent: SafeHtml | null = null;
    loading = false;
    isStaticContent = false;

    constructor(private api: WhitelabelService, private sanitizer: DomSanitizer, private router: Router) { }


    loginFlag(event) {
        this.loginStatus = event;
    }


    ngOnInit() {
        this.loading = true;
        this.api.getPageContent({ path: this.router.url }).subscribe({
            next: (res: any) => {
                if (res?.static) {
                    this.isStaticContent = true;
                    this.loadHtmlContent(res?.html);
                }
                this.loading = false;
            },
            error: () => {
                this.loading = false;
            }
        });
    }

    loadHtmlContent(html) {
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(html);
    }
}