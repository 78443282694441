import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private http: HttpClient) { }

  uploadFile(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}upload-gallery/`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  updateFile(id, data): Observable<any> {
    return this.http.patch(`${environment.apiUrl}gallery/${id}/update/`, data);
  }

  getUploadedFile(params): Observable<any> {
    return this.http.get(`${environment.apiUrl}gallery/`,{params});
  }

  deleteUploadedFile(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}gallery/${id}/update/`);
  }
}