import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {

  constructor(private http: HttpClient) { }

  getWhitelabelPages() {
    return this.http.get<any[]>(`${environment.apiUrl}organization/whitelabel`);
  }

  postWhitelabelPage(user) {
    return this.http.post(`${environment.apiUrl}organization/whitelabel`, user);
  }

  updateWhitelabelPage(user) {
    return this.http.patch(`${environment.apiUrl}organization/whitelabel`, user);
  }

  deletePage(id) {
    return this.http.delete(`${environment.apiUrl}organization/whitelabel/${id}/`);
  }

  getWLStatus() {
    return this.http.get<any[]>(`${environment.apiUrl}organization/whitelabel/status`);
  }

  requestWLAccess(data) {
    return this.http.patch(`${environment.apiUrl}organization/whitelabel/change/status`, data);
  }

  getWLHost() {
    return this.http.get<any[]>(`${environment.apiUrl}organization/whitelabel/host`);
  }

  setWLHost(data) {
    return this.http.post(`${environment.apiUrl}organization/whitelabel/host`, data);
  }

  getWhitelabelList(params: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}organization/whitelabel/change/status`, { params });
  }

  changeAccess(data: any) {
    return this.http.patch(`${environment.apiUrl}organization/whitelabel/change/status`, data);
  }

  getPageContent(url) {
    return this.http.post(`${environment.apiUrl}organization/cms/content`, url);
  }

}
