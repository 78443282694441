import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class DataShareService {
  private excelDataSubject = new BehaviorSubject<any>('');
  excelDataSubject$ = this.excelDataSubject.asObservable();
  private searchSubject = new BehaviorSubject<any>('');
  searchSubject$ = this.searchSubject.asObservable();
  private adminPanelType = new BehaviorSubject<string>('');
  public adminPanel = this.adminPanelType.asObservable();
  private countrySubject = new BehaviorSubject<any[]>([]);
  countrySubject$ = this.countrySubject.asObservable();

  public selectedItemCheckShare =new BehaviorSubject<number[]>([]);
  selectedItemCheckShare$ = this.selectedItemCheckShare.asObservable();
 

  private tableDataSubject = new BehaviorSubject<any[]>([]);

  tableDataSubject$ = this.tableDataSubject.asObservable();
  private categorySubject = new BehaviorSubject<any[]>([]);
  categorySubject$ = this.categorySubject.asObservable();
  private typeSubject = new BehaviorSubject<any[]>([]);
  typeSubject$ = this.typeSubject.asObservable();
  private counrtyCodeSubject = new BehaviorSubject<any[]>([]);
  counrtyCodeSubject$ = this.counrtyCodeSubject.asObservable();
  private borderSetSubject = new BehaviorSubject<boolean>(
    // this.storageService.getSessionStorageItem('proxyToken') ? true : false
    this.storageService.getLocalStorageItem('proxyToken') ? true : false
  );
  borderSetSubject$ = this.borderSetSubject.asObservable();
  moduleList: any[] = [
    'Flight',
    // 'Holiday',
    // 'Visa', ,
    // 'Bus', 'Cab',
    //  'Eurail'
  ];
  status = [
    { value: 'active', viewValue: 'Active' },
    { value: 'inactive', viewValue: 'Inactive' },
    { value: 'pending', viewValue: 'Pending' },
  ];

  holidayStatusList = [
    {
      label: 'ACTIVE',
      value: 'Active',
    },
    {
      label: 'PENDING',
      value: 'Pending',
    },
    {
      label: 'INACTIVE',
      value: 'Inactive',
    },
    {
      label: 'REJECTED',
      value: 'Rejected',
    },
  ];

  countryWithStates = [
    {
      country: 'India',
      states: [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Delhi',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
      ],
    },
    {
      country: 'United Arab Emirates',
      states: [
        'Abu Dhabi',
        'Dubai',
        'Sharjah',
        'Ajman',
        'Umm Al-Quwain',
        'Fujairah',
        'Ras Al Khaimah',
      ],
    },
    {
      country: 'Canada',
      states: [
        'Alberta',
        'British Columbia',
        'Manitoba',
        'New Brunswick',
        'Newfoundland and Labrador',
        'Nova Scotia',
        'Ontario',
        'Prince Edward Island',
        'Quebec',
        'Saskatchewan',
        'Northwest Territories',
        'Nunavut',
        'Yukon',
        'Yukon Territory',
      ],
    },
    {
      country: 'United States of America',
      states: [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Dictrict of Columbia',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
      ],
    },
    {
      country: 'United Kingdom of Great Britain and Northern Ireland',
      states: [
        'East of England',
        'East Midlands',
        'London',
        'North East',
        'North West',
        'South East',
        'South West',
        'West Midlands',
        'Scotland',
        'Wales',
        'Northern Ireland',
      ],
    },
  ];

  private creaditDetails = new BehaviorSubject<any[]>([]);
  creaditDetails$ = this.creaditDetails.asObservable();

  private flightSearchDetails = new BehaviorSubject<any[]>([]);
  flightSearchDetails$ = this.flightSearchDetails.asObservable();

  // flight booking id==============================================
  private flightBookingIdSubject = new BehaviorSubject<any | null>(
    this.getflightBookingIdFromLocalStorage()
  );
  flightBookingId$ = this.flightBookingIdSubject.asObservable();
  // cab booking Id
  private transfersBookingIdSubject = new BehaviorSubject<any | null>(
    this.gettransfersBookingIdFromLocalStorage()
  );
  transfersBookingId$ = this.transfersBookingIdSubject.asObservable();

  //flight passenger details emission=====================================================
  private passengerDetailsSubject = new BehaviorSubject<any | null>(
    this.getPassengerDetails()
  );
  passengerDetailsSubject$ = this.passengerDetailsSubject.asObservable();

  private modifySearchSubject = new BehaviorSubject<any>('');
  modifySearchSubject$ = this.modifySearchSubject.asObservable();

  constructor(private storageService: StorageService) { }

  getadminPanelType(): Observable<string> {
    return this.adminPanelType.asObservable();
  }
  updateSelection(items:number[]){
    console.log(items);
    this.selectedItemCheckShare.next(items);
  }
  removeSelection(index: number) {
    let currentIndexes = this.selectedItemCheckShare.getValue();
    console.log('currentIndexes:', currentIndexes);
  
    // Ensure currentIndexes is an array
    if (!Array.isArray(currentIndexes)) {
      console.error('Error: selectedItemCheckShare is not an array!', currentIndexes);
      currentIndexes = [currentIndexes]; // Convert single value to array
    }
  
    // Filter out the selected index
    const updatedIndexes = currentIndexes.filter((i) => i !== index);
  
    // Update the BehaviorSubject
    this.selectedItemCheckShare.next(updatedIndexes);
  
    console.log('Updated selected indexes:', updatedIndexes);
  }
  setAdminPanelType(adminType: string): void {
    this.adminPanelType.next(adminType);
  }

  setAllCountry(value: any[]) {
    this.countrySubject.next(value);
  }
  setModifySearchRes(value: any) {
    this.modifySearchSubject.next(value);
  }

  setTableData(value: any[]) {
    this.tableDataSubject.next(value);
  }

  setCategoryData(value: any[]) {
    this.categorySubject.next(value);
  }

  setTypeData(value: any[]) {
    this.typeSubject.next(value);
  }

  searchList(value: any) {
    this.searchSubject.next(value);
  }

  setCountryCodes(value: any[]) {
    this.counrtyCodeSubject.next(value);
  }

  setCreaditValue(value: any) {
    const currentValue = this.creaditDetails.getValue();

    // Deep comparison to avoid unnecessary .next()
    if (JSON.stringify(currentValue) !== JSON.stringify(value)) {
      this.creaditDetails.next(value);
    }
  }

  setFlightSearchDetails(value: any) {
    this.flightSearchDetails.next(value);
  }
  private gettransfersBookingIdFromLocalStorage(): Observable<any | null> {
    const transfersBookingId =
      this.storageService.getLocalStorageItem('transfersBookingId');
    return transfersBookingId ? transfersBookingId : '';
  }
  private getflightBookingIdFromLocalStorage(): Observable<any | null> {
    const flightBookingId =
      this.storageService.getLocalStorageItem('flightBookingId');
    return flightBookingId ? flightBookingId : '';
  }

  // Method to set ID
  setflightBookingId(flightBookingId: any): void {
    if (flightBookingId) {
      this.storageService.setLocalStorageItem(
        'flightBookingId',
        flightBookingId
      );
      this.flightBookingIdSubject.next(flightBookingId);
    }
  }
  setTransfersBookingId(cabBookingId: any): void {
    console.log('inside transfer',cabBookingId);
    if (cabBookingId) {
      this.storageService.setLocalStorageItem(
        'transfersBookingId',
        cabBookingId
      );
      this.transfersBookingIdSubject.next(cabBookingId);
    }
  }

  getFlightBookingIdObservable(): Observable<any | null> {
    return this.flightBookingIdSubject.asObservable();
  }
  getTransfersBookingIdObservable(): Observable<any | null> {
    return this.transfersBookingIdSubject.asObservable();
  }

  private getPassengerDetails(): Observable<any | null> {
    const PassengerDetails =
      this.storageService.getLocalStorageItem('passengerDetails');
    return PassengerDetails ? PassengerDetails : '';
  }

  //flight passenger Data emission===========
  setPassengerDetails(value: any[]) {
    if (value) {
      this.storageService.setLocalStorageItem('passengerDetails', value);
      this.passengerDetailsSubject.next(value);
    }
  }

  setBorderData(value: boolean) {
    this.borderSetSubject.next(value);
  }

  getStates(countryName: string) {
    const selectedStates = this.countryWithStates.filter(
      (res) => res?.country?.toLowerCase() == countryName.toLocaleLowerCase()
    )[0]?.states;
    return selectedStates;
  }

  setThemeData() {
    const themeData = this.storageService.getLocalStorageItem('themeData');

    document.documentElement.style.setProperty(
      '--customer-journey-text-color',
      themeData?.customer_journey_text_color
        ? themeData.customer_journey_text_color
        : '#fff'
    );

    document.documentElement.style.setProperty(
      '--customer-journey-button-first-color',
      themeData?.customer_journey_button_first_color
        ? themeData?.customer_journey_button_first_color
        : '#601111'
    );

    document.documentElement.style.setProperty(
      '--customer-journey-button-second-color',
      themeData?.customer_journey_button_second_color
        ? themeData?.customer_journey_button_second_color
        : '#C02122'
    );

    document.documentElement.style.setProperty(
      '--general-button-text-color',
      themeData?.general_button_text_color
        ? themeData?.general_button_text_color
        : '#fff'
    );

    document.documentElement.style.setProperty(
      '--general-button-first-color',
      themeData?.general_button_first_color
        ? themeData?.general_button_first_color
        : 'rgb(72, 94, 117)'
    );

    document.documentElement.style.setProperty(
      '--general-button-second-color',
      themeData?.general_button_second_color
        ? themeData?.general_button_second_color
        : 'rgb(29, 127, 222)'
    );

    document.documentElement.style.setProperty(
      '--navbar-background--color',
      themeData?.nav_bar_bg_color ? themeData?.nav_bar_bg_color : '#fff'
    );

    document.documentElement.style.setProperty(
      '--navbar-text--color',
      themeData?.nav_bar_text_color ? themeData?.nav_bar_text_color : '#000'
    );

    document.documentElement.style.setProperty(
      '--navbar-selected-text-color',
      themeData?.nav_bar_selected_text_color
        ? themeData?.nav_bar_selected_text_color
        : '#1150A0'
    );

    document.documentElement.style.setProperty(
      '--navbar-selected-bg-color',
      themeData?.nav_bar_selected_bg_color
        ? themeData?.nav_bar_selected_bg_color
        : '#E6EAF1'
    );

    document.documentElement.style.setProperty(
      '--navbar-dropdown-color',
      themeData?.nav_bar_drop_down_color
        ? themeData?.nav_bar_drop_down_color
        : '#000'
    );

    document.documentElement.style.setProperty(
      '--loader-color',
      themeData?.loader_color ? themeData?.loader_color : '#3F51B5'
    );
  }

  setExcelData(data: any) {
    this.excelDataSubject.next(data);
  }
}
