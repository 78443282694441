import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FlightService {
  constructor(private http: HttpClient) { }

  searchAirPortList(search_key) {
    return this.http.get(
      `${environment.apiUrl}search_airport?search_key=${search_key}`
    );
  }

  // flight serach api for getting the session id

  getSessionIdFromFlightSearch(flightSearchForm) {
    return this.http.post(
      `${environment.flightApi}flights/flight-search`,
      flightSearchForm
    );
  }

  //----------------------To get flight list using session id-------------------------------------------

  getFlightSerachResults(sessionIdForm) {
    return this.http.post(
      `${environment.flightApi}flights/flight-search-data`,
      sessionIdForm
    );
  }
  getAirportList() {
    return this.http.get(`${environment.apiUrl}lookup_airport`);
  }
  getMarkupValue(data) {
    return this.http.get(`${environment.apiUrl}markup/view?module=${data}`);
  }
  getUserJourney(fareRequestBody){
    return this.http.post(`${environment.flightApi}common/user_journey_details`,fareRequestBody);
  }

  getAirPortWithCountryCode(params: any) {
    return this.http.get(
      `${environment.apiUrl}lookup-airport-deal-managaement`,
      { params }
    );
  }
  getDefaultValues(countryId) {
    return this.http.get(
      `${environment.apiUrl}default_values?country_id=${countryId}`
    );
  }
  getAccountBalance() {
    return this.http.get(
      `${environment.apiUrl}accounting/shared/credit-balance`
    );
  }
  getFlightFareDetails(fareRequestBody) {
    return this.http.post(
      `${environment.flightApi}flights/fare-details`,
      fareRequestBody
    );
  }
  getFlightAirPricing(fareRequestBody) {
    return this.http.post(
      `${environment.flightApi}flights/air-pricing`,
      fareRequestBody
    );
  }
  createFlightBooking(flightBookingForm) {
    return this.http.post(
      `${environment.flightApi}flights/create-booking`,
      flightBookingForm
    );
  }
  getFareRules(flightBookingForm) {
    return this.http.post(
      `${environment.flightApi}flights/fare-details`,
      flightBookingForm
    );
  }
  paymentMethod(paymentRequest) {
    return this.http.post(
      `${environment.flightApi}flights/purchase`,
      paymentRequest
    );
  }
  purchaseStatus(purchaseStatusRequest) {
    return this.http.post(`${environment.flightApi}flights/purchase-status`, purchaseStatusRequest)
  }
  getFlightSsr(data) {
    return this.http.post(`${environment.flightApi}flights/flight-ssr`, data);
  }
  getPaymentSuccessDetails(bookingRequest) {
    return this.http.post(
      `${environment.flightApi}flights/payment-success`,
      bookingRequest
    );
  }
  getPreferedAirlines(searchTerm) {
    return this.http.get(`${environment.apiUrl}airline?search=${searchTerm}`);
  }
  checkHoldBookings(holdingResponse): Observable<any> {
    return this.http.post(
      `${environment.flightApi}flights/check-hold`,
      holdingResponse
    );
  }
  createHoldBooking(holdingBookingResponse) {
    return this.http.post(
      `${environment.flightApi}flights/hold-booking`,
      holdingBookingResponse
    );
  }
  getFlightFailure(requestBody) {
    return this.http.post(
      `${environment.flightApi}flights/booking-status`,
      requestBody
    );
  }

  //////////////proxy api/////////////////////////////////////////////////////////////////////////////////

  changeProxyStatus(data: any) {
    return this.http.post(
      `${environment.apiUrl}user/profile/proxy-status`,
      data
    );
  }

  getProxyStatus() {
    return this.http.get(`${environment.apiUrl}user/profile/proxy-status`);
  }

  checkRecharge() {
    return this.http.get(
      `${environment.apiUrl}accounting/shared/show-recharge-button/`
    );
  }
  getPassengersList(search,type) {
    return this.http.get(
      `${environment.apiUrl}passenger/details/list?name=${search}&type=${type}`);
  }

  //////////////////////////Offline ticketing//////////////////////////////////////////////////

  getSupplierListUsingProvider(provider) {
    return this.http.get(
      `${environment.flightApi}flights/office-codes?provider=${provider}&&vendor=${provider}`
    );
  }
  getOfflineBillingImportPnr(offlinePnrDetails) {
    return this.http.post(
      `${environment.flightApi}flights/offline-billing-import-pnr`, offlinePnrDetails
    );
  }
  getCreditDetails() {
    return this.http.get(
      `${environment.flightApi}flights/credit-cards`
    );
  }
  getSuplierList() {
    return this.http.get(
      `${environment.flightApi}flights/supplier-list`
    );
  }
  getAgencyList() {
    return this.http.get(
      `${environment.flightApi}flights/agency-list`
    );
  }
  getCreateBill(billDetails) {
    return this.http.post(
      `${environment.flightApi}flights/offline-billing-create-bill`, billDetails
    );
  }
 

  //////////////////////////////Import pnr api for adding the data to the easy link
  getImportPnrDetails(offlinePnrDetails) {
    return this.http.post(
      `${environment.flightApi}flights/ticketing-import-pnr`, offlinePnrDetails
    );
  }
  getRepricingValues(repricing) {
    return this.http.post(
      `${environment.flightApi}flights/ticketing-repricing`, repricing
    );
  }
  getImportPnrPaxDetails(importPnrPaxRequestBody) {
    return this.http.post(
      `${environment.flightApi}flights/ticketing-create`, importPnrPaxRequestBody
    );
  }
  createImportPnrBilling(billDetails) {
    return this.http.post(
      `${environment.flightApi}flights/ticketing-create-bill`, billDetails
    );
  }
  getSelectedFareRules(data){
    return this.http.post(`${environment.flightApi}flights/get-fare-rule`, data);
  }
  updateFailedBooking(data) {
    return this.http.post(
      `${environment.flightApi}flights/update-failed-booking`, data
    );
  }
  ///////////////////send Mail
  sendMail(requestBody) {
    return this.http.post(
      `${environment.apiUrl}invoke/event`, requestBody
    );
  }
  ////////////////agency list with search keyword/////////////
  getAgencyWithSearckKeyword(serachKey) {
    return this.http.get(
      `${environment.apiUrl}organization-list?search=${serachKey}`
    );
  }

  sendFlightFareMail(sendMailRequest){
    return this.http.post(`${environment.apiUrl}invoke/share/fare`, sendMailRequest);
  }

}
