import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from "@angular/core";
import { CommonService } from "src/app/core/services/common.service";
import { DataShareService } from "src/app/core/services/datashare.sevice";
import { StorageService } from "src/app/core/services/storage.service";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'BTA-home-header',
  standalone: true,
  imports : [CommonModule],
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})

export class HomeHeaderComponent {
  @Output() loginFlag = new EventEmitter();
  @ViewChild('dropDown') dropDown!: ElementRef;
  countries = [];
  isDropdownOpen = false;
  selectedCountry = {
    "name": '',
    "country_code": '',
    "currency_symbol": '',
    "id": '',
    // "customerNo": '',
    "flag": ""
  }
  //"assets/images/flags/IN.svg";
  constructor(private renderer: Renderer2, private storageService: StorageService, private commonService: CommonService,private sharedService:DataShareService) { }

  ngOnInit() {
    this.sharedService.counrtyCodeSubject$.subscribe((response: []) => {
      response.forEach((element: any) => {
        const myJson = {
          "name": element.country_name,
          "country_code": element.country_code,
          "currency_symbol": element.currency_symbol,
          "id": element.id,
          "customerNo": element.customercare_no_Flight,
          "calling_code": element.calling_code,
          "flag": "https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/flags/" + element.country_code + '.svg'
        }
        this.countries.push(myJson);
      });
    })
    this.getInitailSetUp();
  }
  getInitailSetUp(){
    this.commonService.getInit().subscribe((response : Response)=>{
      let selectedCountry = JSON.parse(JSON.stringify(response))
      this.selectedCountry = {
        "name": selectedCountry.country_name,
        "country_code": selectedCountry.country_code,
        "currency_symbol": selectedCountry.currency_symbol,
        "id": selectedCountry.country_id,
        "flag": "https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/flags/" + selectedCountry.country_code + '.svg'
      }
      this.storageService.setLocalStorageItem('selected_country', this.selectedCountry)
    })
  }

  toggleCountryDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.renderer.removeStyle(this.dropDown.nativeElement, 'display',)
    } else {
      this.renderer.setStyle(this.dropDown.nativeElement, 'display', 'none')
    }
  }
  selectCountry(country: any) {
    this.storageService.setLocalStorageItem('selected_country', country)
    this.selectedCountry = country;
  }
  navigateToLogin() {
    this.loginFlag.emit(true)
  }
}