import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { AppDeleteComponent } from '../app-delete/app-delete.component';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { GalleryService } from '../../../core/services/gallery.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Paginator } from 'src/app/core/models/table.model';

interface DialogData {
  isMultiSelect?: any;
  fileType?: any;
  files: any;
  module: any;
}
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss',
})
export class GalleryComponent {
  isLoading: boolean;
  galleryList: any;
  imgCount = 0;
  imageForm: FormGroup;
  fileToUpload: File | null = null;
  progress: number;
  isMultiSelect = false;
  selectedImage: any;
  selectedIndex: any;
  isForm = false;
  fileType = '';
  private dataSubscription: Subscription;
  public module: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dialogDelRef: MatDialogRef<AppDeleteComponent>;
  pagination: Paginator = {
    search: '',
    length: 0,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
  };
  constructor(
    public dialogRef: MatDialogRef<GalleryComponent>,
    public galleryService: GalleryService,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private fb: FormBuilder,
    private tostrService: ToastService,
    public dialog: MatDialog
  ) {
    this.isMultiSelect = data.isMultiSelect;
    this.fileType = data.fileType;
    this.module = data.module;
  }

  ngOnInit() {
    this.getImages();
    this.resetGalleryForm();
  }

  getImages() {
    let paramsData: any = {
      page: this.pagination.pageIndex + 1,
      page_size: this.pagination.pageSize,

      module: this.module,
    };
    this.galleryService.getUploadedFile(paramsData).subscribe((files) => {
      this.galleryList = files?.results;
      this.pagination.length = files?.total_data;
    });
   
  }

  onSelectImage(image, selectedIndex?) {
    if (this.isMultiSelect) {
      image.selected = !image.selected;
      this.imgCount = this.galleryList.reduce(
        (count, item) => count + (item.selected ? 1 : 0),
        0
      );
    } else {
      this.resetGalleryForm(image);

      this.galleryList.forEach((image, index) => {
        image.selected = index === selectedIndex;
      });
      // this.selectedImage = image;
      this.selectedIndex = selectedIndex;
    }
  }

  onSubmit() {
    let images = this.galleryList.filter((ae) => ae.selected);
    this.dialogRef.close(images);
  }

  onImageSubmit(form) {
    if (!form.value.id) {
      const formData: FormData = new FormData();
      formData.append('url', this.fileToUpload);
      formData.append('alternative_text', form.value.alternative_text);
      formData.append('document_type', this.fileType);
      formData.append('module', this.module);
      formData.append('name', form.value.name);
      // formData.append('title', form.value.title);
      this.galleryService.uploadFile(formData).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.progress = Math.round((100 * event.loaded) / event.total);
              break;
            case HttpEventType.Response:
              this.progress = 0;
              this.galleryList.forEach((image) => {
                image.selected = false;
              });
              this.tostrService.success('Success!', 'Added Successfully!');
              this.getImages();
              break;
          }
          this.getImages();
          this.isForm = false;
          this.selectedImage = null;
          this.resetGalleryForm();
        },
        (error: any) => {
          this.progress = 0;
        }
      );
    } else {
      this.galleryService
        .updateFile(form.value.id, form.value)
        .subscribe(() => {
          this.tostrService.success('Success!', 'Updated Successfully!');
          this.getImages();
          this.isForm = false;
          this.selectedImage = null;
          this.resetGalleryForm();
        });
    }
  }

  resetGalleryForm(data?) {
    this.imageForm = this.fb.group({
      url: [data ? data.url : '', [Validators.required]],
      alternative_text: [data ? data.alternative_text : ''],
      id: [data ? data.id : null],
      name: [data ? data.name : null],
      // title: [data ? data.title : ''],
      module: [this.module],
    });
  }

  handleIcon(files: FileList) {
    if(files[0].name.includes('.svg')){
      (document.getElementById('uploadImg') as HTMLInputElement).value = '';
      this.tostrService.warning('Svg images are not allowed','Warning')
      return
    }
    this.isLoading = true;
    this.fileToUpload = files.item(0);
  }

  triggerClick(id) {
    let element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  resetSelection() {
    this.galleryList = this.galleryList.map((obj) => {
      return { ...obj, selected: false };
    });
    this.selectedImage = null;
    this.selectedIndex = null;
  }



  ngOnDestroy() {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }

  deleteImage(item) {
    this.dialogDelRef = this.dialog.open(AppDeleteComponent, {
      disableClose: true,
      data: {
        type: 'Integration',
        title: item.name,
      },
      width: '300px',
    });
    this.dialogDelRef.afterClosed().subscribe((res) => {
      if (res.toLowerCase() === 'yes') {
        this.galleryService.deleteUploadedFile(item.id).subscribe(
          (res: Response) => {
            this.tostrService.success('Your file has been deleted.', 'Cancel');
            this.getImages();
          },
          (error) => {
            this.tostrService.error('Your delete has been failed.', 'Cancel');
          }
        );
      }
    });
  }

  pageChangedFun(event: any) {
    this.pagination.pageIndex = event?.pageIndex;
    this.pagination.pageSize = event?.pageSize;
    this.getImages();
  }
}
