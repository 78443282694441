import { Injectable } from '@angular/core';


import { Credentials, OtpData, Password, User, authResponse, authSubject, loginResponse } from '../models/auth.models';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<authSubject>;
    public currentUsers: Observable<authSubject>;
    user: User;

    constructor(private http:HttpClient,private storageService:StorageService) {
        this.currentUserSubject = new BehaviorSubject<authSubject>(storageService.getSessionStorageItem('token'));
        this.currentUsers = this.currentUserSubject.asObservable();
    }

    /**
     * Returns the current user
     */
    public currentUser() {
       return this.currentUserSubject.value
    }

    public setValue(){
        this.currentUserSubject.next(this.storageService.getSessionStorageItem('token'))
    }

    

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(credentials:Credentials) {
      return this.http.post<loginResponse>(`${environment.apiUrl}verify/user`,credentials)
    }

    verifyOtp(otpData:OtpData){
     return this.http.post<authResponse>(`${environment.apiUrl}otp/verify`,otpData)
    }

    generateAccessToken(token){
        return this.http.post<authResponse>(`${environment.apiUrl}api/auth/token/refresh/`,token)
        
    }

    updatePassword(password:Password){
        return  this.http
        .post(`${environment.apiUrl}update/password`, password)
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
       ;
    }

    /**
     * Logout the user
     */
  
    user_registration(userDetails : any){
        return this.http.post(`${environment.apiUrl}registration`,userDetails)
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.clear();
        this.currentUserSubject.next(null);
    }
}

