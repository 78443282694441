<div class="blue-overlay pt-lg-5 pt-3 pb-lg-5 pb-3 text-center">
    <div class="container">
        <div class="h1 h1-size fw-bolder mb-lg-5 mb-3 text-white">The figures reveal the truth</div>
        <div class="row ">
            <div class="col mb-4">
                <div class="bg-white rounded-3 p-4">
                    <div align="center"><img src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/home/customers.svg" height="80"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">500K+</div>
                    <p class="text-center fw-bold mb-0">Customers</p>
                </div>
            </div>
            <div class="col mb-4">
                <div class="bg-white rounded-3 p-4">
                    <div align="center"><img src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/home/agents.svg" height="80"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">230K+</div>
                    <p class="text-center fw-bold mb-0">Agents</p>
                </div>
            </div>
            <div class="col mb-4">
                <div class="bg-white rounded-3 p-4">
                    <div align="center"><img src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/home/flight-icon.svg" height="80"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">350K+</div>
                    <p class="text-center fw-bold mb-0">Flight Bookings</p>
                </div>
            </div>
            <div class="col mb-4">
                <div class="bg-white rounded-3 p-4">
                    <div align="center"><img src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/home/hotel.svg" height="80"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">150K+</div>
                    <p class="text-center fw-bold mb-0">Hotel Bookings</p>
                </div>
            </div>
            <div class="col ">
                <div class="bg-white rounded-3 p-4">
                    <div align="center"><img src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/home/holiday.svg" height="80"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">180K+</div>
                    <p class="text-center fw-bold mb-0">Holiday Bookings</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="red-overlay pt-lg-5 pt-3 pb-lg-5 pb-3 text-center">
    <div class="h2 fw-medium mb-3 text-white">Learn about the technological innovations that <br>
        support the growth of the tourism industry.</div>
    <button class="btn btn-lg white-btn blue-text fw-bold">Contact Us</button>
</div>