import { Component, ElementRef, Input, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-static-page',
  standalone: true,
  template: ` <iframe #iframeEl [srcdoc]="htmlContent" width="100%" height="100%" 
      sandbox="allow-scripts allow-same-origin allow-top-navigation-by-user-activation"
      style="position: absolute;" frameborder="0">
    </iframe>`,
  encapsulation: ViewEncapsulation.ShadowDom
})
export class StaticPageComponent {
  @Input() htmlContent!: string;
  @ViewChild('iframeEl') iframeEl!: ElementRef<HTMLIFrameElement>;

  constructor(private ngZone: NgZone) { }

  ngAfterViewInit() {
    setTimeout(() => {
      const iframe = this.iframeEl?.nativeElement;
      if (!iframe) {
        console.error('Iframe not found');
        return;
      }

      const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
      if (!iframeDoc) {
        console.error('Iframe document is not accessible');
        return;
      }

      // Handle clicks inside iframe
      iframeDoc.addEventListener('click', (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (target.tagName === 'A' && target.hasAttribute('href')) {
          event.preventDefault();
          const url = target.getAttribute('href');

          if (url) {
            // Run navigation inside Angular's Zone
            this.ngZone.run(() => {
              // this.router.navigate([url]);
              window.location.replace(url);
            });
          }
        }
      });
    }, 500); // Ensure iframe content is loaded
  }

}
