@if(isBorder){
<div class="full-screen-div" *ngIf="isBorder">
 
    <router-outlet></router-outlet>

</div>

}@else{
<router-outlet></router-outlet>
}
