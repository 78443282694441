import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationSettingsService {
  private themeSubject = new BehaviorSubject('');
  themeSubject$ = this.themeSubject.asObservable();
  constructor(private http: HttpClient) {}

  setThemeSubject() {
    this.themeSubject.next('');
  }
  returnCustomisedObject(object: any) {
    const data = Object.entries(object?.themeData?.currentValue).reduce(
      (acc, [key, value]) => {
        if (typeof value === 'string') {
          value = value.replace('#fff', '#ffffff'); // Replace #fff with #ffffff
        }
        acc[key] = value; // Add the updated key-value pair to the accumulator
        return acc; // Return the accumulator
      },
      {} as Record<string, any> // Initialize as an empty object
    );
    return data;
  }
  getTokenDetails(): Observable<any> {
    return this.http.get(`${environment.apiUrl}accesstoken/detail`);
  }

  requestAccess(data: any) {
    return this.http.patch(`${environment.apiUrl}outapi/status/change`, data);
  }

  generate() {
    return this.http.post(`${environment.flightApi}api/auth/token`, {});
  }

  getAllApis(params: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}list/outapi`, { params });
  }

  getAllThemesDataList(): Observable<any> {
    return this.http.get(`${environment.apiUrl}theme/detail`);
  }

  updateThemesData(data: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}theme/detail`, data);
  }
}
