import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class HolidayService {
  constructor(private http: HttpClient) { }

  getAllCountry(): Observable<any> {
    return this.http.get(`${environment.apiUrl}lookup_country_get`);
  }

  getPriceCountryList(): Observable<any> {
    return this.http.get(`${environment.apiUrl}country`);
  }
  postHoliday(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}holiday_product`, data);
  }

  updateHoliday(data: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}holiday_product`, data);
  }

  getAllProducts(params: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}holiday_product`, { params: params });
  }

  getProductDetail(id: any) {
    return this.http.get(`${environment.apiUrl}holiday_product?id=${id}`);
  }

  deleteProduct(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}holiday_product?id=${id}`);
  }
  //theme api
  addTheme(data: any) {
    return this.http.post(`${environment.apiUrl}holiday_theme`, data);
  }
  getAllThemes(): Observable<any> {
    return this.http.get(`${environment.apiUrl}holiday_theme`);
  }
  updateTheme(data: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}holiday_theme`, data);
  }

  deleteTheme(id: string) {
    return this.http.delete(`${environment.apiUrl}holiday_theme?id=${id}`);
  }

  getHolidayStatus() {
    return this.http.get(`${environment.apiUrl}holiday_approval_status`);
  }

  changeStatus(data: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}holiday_approval_status`, data)
  }

  searchHolidayList(search) {
    return this.http.get(`${environment.apiUrl}web/holiday/search/${search ? `?search=${search}` : ''}`);
  }

  holiadaySearchResult(params:any,filterData) {
    return this.http.post(`${environment.apiUrl}web/holiday/search-result/`, filterData,{params})
  }

  getHolidayDetails(slug) {
    return this.http.get(`${environment.apiUrl}web/single/product/slug/${slug}/`)
  }

  holidayEnquiry(enquiryForm) {
    return this.http.post(`${environment.apiUrl}web/holiday/enquiry/`, enquiryForm)
  }

  getAllFavProducts(url: string, params: any) {
    return this.http.get(`${environment.apiUrl}${url}`, { params })
  }

  addToFav(url: string, data: any) {
    return this.http.post(`${environment.apiUrl}${url}`, data)
  }

  getSkuProducts(url: string, params: any) {
    return this.http.get(`${environment.apiUrl}${url}`, { params })
  }

  romveFromFvt(url: string, id: string) {
    return this.http.delete(`${environment.apiUrl}${url}?id=${id}`)
  }

  //---------------------------------favourite holiday list api for web------------------------
  getFavoriteHolidays(selectedCountryId) {
    return this.http.get(`${environment.apiUrl}web/holiday/favourites/?country_id=${selectedCountryId}`);
  }

  getHolidaySuggestions(params:any){
    return this.http.get(`${environment.apiUrl}holiday_default_values`,{params}); 
  }

  //Que-apis
  getQueueData(url:string,params:any){
    return this.http.get(`${environment.apiUrl}${url}`,{params});
  }

  getStatusList(url:string){
    return this.http.get(`${environment.apiUrl}${url}`);
  }

  changeQueStatus(url:string,data:any){
    return this.http.patch(`${environment.apiUrl}${url}`,data);
  }

  getSupplierList(){
    return this.http.get(`${environment.apiUrl}holiday_suppliers`);
  }
}
