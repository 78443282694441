<ng-container *ngIf="!isStaticContent && !loading">
    <div class="my-5 pt-sm-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-center">
                        <div>
                            <div class="row justify-content-center">
                                <div class="col-sm-4">
                                    <div class="error-img">
                                        <img [src]="imageUrl + 'assets/images/404-error.png'" alt=""
                                            class="img-fluid mx-auto d-block">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="text-uppercase mt-4">Sorry, page not found</h4>
                        <p class="text-muted">The page you requested was not found.</p>
                        <div class="mt-5">
                            <a class="btn btn-primary" routerLink="/">Back to Home</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isStaticContent && !loading && htmlContent">
    <app-static-page [htmlContent]="htmlContent"></app-static-page>
</ng-container>