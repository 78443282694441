import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { HomePageComponent } from './home-page.component';
import { BannerSectionComponent } from './banner-section/banner-section.component';
import { ComprehensiveSectionComponent } from './comprehensive-section/comprehensive-section.component';
import { MidSectionComponent } from './mid-section/mid-section.component';
import { ProductsComponent } from './products/products.component'
import { BtmSectionComponent } from './btm-section/btm-section.component';
import { FooterComponent } from './footer/footer.component';
import { SharedMaterialModule } from '../shared-material.module';
import { LoginComponent } from './login/login.component';
import { BtaStaticPageModule } from '../shared/bta-static-page/bta-static-page.module';
import { BusinessSectionComponent } from './business-section/business-section.component';
import { StatSectionComponent } from './stat-section/stat-section.component';
import { LoginGuard } from '../core/guards/login-guard';
import { StaticPageComponent } from '../shared/static-page/static-page.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
        canActivate: [LoginGuard]
    },
    { path: 'account', loadChildren: () => import('../account/account.module').then(m => m.AccountModule) },
    { path: 'static-pages', loadChildren: () => import('./static-contents/static-content-routing.module').then(m => m.StaticContentModule) }

];

@NgModule({
    declarations: [

        HomePageComponent,
        BannerSectionComponent,
        ComprehensiveSectionComponent,
        MidSectionComponent,
        ProductsComponent,
        BtmSectionComponent,
        LoginComponent,
        BusinessSectionComponent,
        StatSectionComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        HomeHeaderComponent,
        FormsModule,
        ReactiveFormsModule,
        SharedMaterialModule,
        BtaStaticPageModule,
        FooterComponent,
        StaticPageComponent
    ],
    providers: [],
    exports: [
        // HomeHeaderComponent, 
        HomePageComponent,
        BannerSectionComponent,
        ProductsComponent,
        BtmSectionComponent,
        LoginComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})

export class HomePageModule { }

