<div id="layout-wrapper">
  <app-horizontaltopbar *ngIf="!excludeRoutes()" (mobileMenuButtonClicked)="onToggleMobileMenu()"
    (settingsButtonClicked)="onSettingsButtonClicked()"></app-horizontaltopbar>

  <div class="main-content min-height-style">
    <!-- <div class="page-content"> -->
      <nz-water-mark nzContent="">

        <router-outlet></router-outlet>
      </nz-water-mark>
    <!-- </div> -->
  </div>

  <!-- <app-footer></app-footer> -->
  <div class="footer">
    <BTA-footer></BTA-footer>
  </div>
  <app-rightsidebar></app-rightsidebar>
</div>