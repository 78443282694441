import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExcelConvertionComponent } from './excel-convertion/excel-convertion.component';

@NgModule({
  declarations: [],
  imports: [CommonModule, UIModule],
})
export class SharedModule {}
