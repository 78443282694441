import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(private http: HttpClient) { }

    getInit() {
        return this.http.get(`${environment.apiUrl}api/user-ip/`)
    }

    getVerifyGstin(gst_number) {
        return this.http.get(`${environment.apiUrl}verify_gstin/?gst_number=${gst_number}`)
    }
    getAllCountry(): Observable<any> {
        return this.http.get(`${environment.apiUrl}lookup_country_get`);
    }
    getStateAgainstCountry(){
        return this.http.get(`assets/json/state-list.json`);
    }

}
//api/user-ip/