<div class="modal-header" *ngIf="isShown">
    <h6 class="modal-title w-100 text-center header"> {{title}}</h6>
    <button type="button" class="btn-close" (click)="dialogRefClose();">
    </button>
</div>
<div class="modal-header" *ngIf="!isShown">
    <h6 class="modal-title w-100 text-center header">{{view_status ? 'View' : isUpdated?
        'Edit' : 'Add'}} {{title}}</h6>
    <button type="button" class="btn-close" (click)="dialogRefClose();">
    </button>
</div>